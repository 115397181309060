import { Link } from 'react-router-dom';
import '../Styles/HeaderComponentStyle.css';

function HeaderComponent() {

    let prevScrollPos = window.scrollY || window.pageYOffset;

    if(window.innerWidth > 767){
        window.addEventListener('scroll', function() {
            const header = document.getElementById("header-wrapper");
            const headerHeight = header.offsetHeight;
            let currentScrollPos = window.scrollY;
        
            if (prevScrollPos > currentScrollPos) {
                header.style.top = "0";
            } else if (prevScrollPos <= currentScrollPos && currentScrollPos > 220){
                header.style.top = `-${headerHeight}px`;
            }

            prevScrollPos = currentScrollPos;
        });

        
    }

    function handleSwitch(){    
        var headerSwitch = document.getElementById('header-m-switch');
        var mainNav = document.getElementById('header');
        headerSwitch.classList.toggle('header-m-switch-on');
        mainNav.classList.toggle('header-nav-on');
    }


    document.addEventListener('click', event => {
        var headerWrapper = document.getElementById('header-wrapper')
        var hSwitch = document.getElementById('header-m-switch');
        var mN = document.getElementById('header');
        var isClickInside = headerWrapper.contains(event.target)
        if(hSwitch.contains(event.target)){
            isClickInside = true;
        }

        if (!isClickInside) {
            hSwitch.classList.remove('header-m-switch-on');
            mN.classList.remove('header-nav-on');
        }
    })

  return (
    <nav id="header" className="header-component" 
    data-aos="fade-zoom-in"
    data-aos-easing="ease-in-back"
    data-aos-offset="0"
    data-aos-duration="3000"
    data-aos-once='true' 
    >
        <div className='header-m-switch' id='header-m-switch' >
            <div className='header-m-switch-menu'>
                <span className='header-m-switch-menu-svg' onClick={handleSwitch}></span>
            </div>
            <div className='header-m-switch-close'>
                <span className='header-m-switch-close-svg' onClick={handleSwitch}></span>
            </div>
        </div>

        <div className="header-wrapper" id="header-wrapper">
            <div className='header-container' id='header-container'>
                <div className='header-inner'>
                    <div className="header-section">
                        <Link to="/" className="header-link"> 1032 </Link>
                    </div>
                    {/* <div className="header-section">
                        <a className="header-link" href="#about">
                            about us
                        </a>
                    </div>
                    <div className="header-section">
                        <a className="header-link" href="#reviews">
                            reviews
                        </a>
                    </div> */}
                    <div className="header-section">
                        <Link to="contact" className="header-link">contact</Link>
                    </div>
                </div>
            </div>
        </div>
    </nav>
  );
}

export default HeaderComponent;
