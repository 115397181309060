import '../Styles/BackgroundComponentStyle.css';
// function FuzzLayer() {
//   var body = document.body,
//     html = document.documentElement;

//   var height = Math.max( body.scrollHeight, body.offsetHeight, 
//                        html.clientHeight, html.scrollHeight, html.offsetHeight );
//   return (
//     <div className="fuzz-layer" style={{height: height + 'px'}}>
//         <img src={noise} alt="noise" class="fuzz-layer-img" ></img>

//     </div>
//   );
// }


function FuzzLayer() {
  return (
    <div className="fuzz-layer" >
    </div>
  );
}

export default FuzzLayer;
