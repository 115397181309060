import {useEffect} from "react";
import '../Styles/Rotate3dComponentStyle.css';

function Rotate3dComponent() {
  useEffect(() => {

  }, []);

  return (
    <div className="rotate-component">
        <div className="scene">
	<div className="cube cube1">
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="ball"></div>
	</div>
	<div className="cube cube2">
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="ball"></div>
	</div>
	<div className="cube cube3">
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="ball"></div>
	</div>
	<div className="cube cube4">
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="ball"></div>
	</div>
	<div className="cube cube5">
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="ball"></div>
	</div>
	<div className="cube cube6">
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="ball"></div>
	</div>
	<div className="cube cube7">
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="ball"></div>
	</div>
	<div className="cube cube8">
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="cube__side"></div>
		<div className="ball"></div>
	</div>
</div>
    </div>
  );
}

export default Rotate3dComponent;
