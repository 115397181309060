import '../Styles/HomePageStyle.css';
import HeroComponent from '../Components/HeroComponent'
import BigTextComponent from '../Components/BigTextComponent'
import CardsComponent from '../Components/CardsComponent'
// import CardsComponentPricing from '../Components/CardsComponentPricing'
// import CarouselComponent from '../Components/CarouselComponent'
import SpacerComponent from '../Components/SpacerComponent'
import ReviewsComponent from '../Components/ReviewsComponent'
import SplitComponent from '../Components/SplitComponent'
import FuzzLayer from '../Components/FuzzLayer'


function HomePage() {
  window.scrollTo(0, 0);
  return (
    <div class="homepage">
      <FuzzLayer />
      <HeroComponent />
      <BigTextComponent 
        text1={"website development "} 
        textFx={"and "} 
        text2={"digital marketing."} 
        setId={"big-text-one"}
      />
      <SplitComponent 
        setId={"split-id-one"}  
      />
            <BigTextComponent 
        text1={"give your big "} 
        textFx={"ideas "} 
        text2={"space to flourish."} 
        setId={"big-text-three"}
      />
      <CardsComponent />
      {/* <SpacerComponent /> */}
      <BigTextComponent 
        text1={"see what all the "} 
        textFx={"fuss "} 
        text2={"is about"} 
        setId={"big-text-two"}
      />
      {/* <CarouselComponent /> */}
      {/* <SpacerComponent /> */}
      <ReviewsComponent /> 
      {/* <SpacerComponent /> */}
      {/* <SpacerComponent /> */}
      {/* <CardsComponentPricing /> */}
      <SpacerComponent />

    </div>
  );
}

export default HomePage;
