import { Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import '../Styles/ReviewsComponentStyle.css';
import '../Styles/CarouselComponentStyle.css';

function ReviewsComponent() {
  return (
    <div className="reviews-component margin-spacing">
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}  
        data-aos="fade-up"    >

          <SwiperSlide>
          <div className='slide-container' >
            
              <div className='slide-title'>
                <h4>
                Really helped us with our website!</h4>
              </div>
              <div className='slide-body'>
                <p>
                 We were launching a new shop on our website and 1032 came to the rescue. Great to work with you! Super flexible, always responsive and delivered everything we could think of. Thanks again.
                </p>
              </div>
              <div className='slide-subname'>
                <p>Francesco from <a href="https://www.sgaiafoods.co.uk/" target="_blank" rel="noreferrer">Sgaia Foods</a></p>
              </div>
            </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className='slide-container' >
            
              <div className='slide-title'>
                <h4>
                Many thanks from us all at Stewart IFA.
                </h4>
              </div>
              <div className='slide-body'>
                <p>
                I would highly recommend 1032 digital if you are looking for a new website as an individual or company. They are always available while providing a dedicated, friendly and prompt service. I felt they really went the extra mile to take all feedback on board to tailor the website depending on our specific needs as a company.
                </p>
              </div>
              <div className='slide-subname'>
                <p>Ryan from <a href="https://www.stewartifa.co.uk/" target="_blank" rel="noreferrer">Stewart IFA</a></p>
              </div>
            </div>
        </SwiperSlide>
        
      </Swiper>
    </div>
  );
}

export default ReviewsComponent;
