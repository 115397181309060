import 'aos/dist/aos.css';
import '../Styles/CardsComponentStyle.css';
import devices from '../Media/images/devices.webp'
import frontend from '../Media/images/front-end.webp'
import meetingroom from '../Media/images/meeting-room.webp'
import network from '../Media/images/network.webp'
import onlineshop from '../Media/images/online-shop.webp'
import seo from '../Media/images/seo.webp'
import BackgroundComponent from '../Components/BackgroundComponent'



function CardsComponent() {

  return (
    <div className="cards-component margin-spacing" id="cards-component">

      <BackgroundComponent 
        poss1={ {x:20,y:10} }
        poss2={ {x:-40,y:0} }
        pose1={ {x:-20,y:-60} }
        pose2={ {x:-40,y:-60} }
        e2display= { "auto" }
        id={"background-blob-storage-cards"}
      />

      {/* <div className="cards-component-title"><h2>we take care of</h2></div> */}
      
      <div className="cards-component-inner" id="services">
        
        <div className='card-wrapper'  data-aos="fade-up" data-aos-easing="ease-in-out">
          <div className='card-text' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out">
            <h3>responsive website development</h3>
          </div>
          <div className='card-image' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out"><img src={devices} alt="devices logo"/></div>
        </div>
        <div className='card-wrapper' data-aos="fade-up" data-aos-easing="ease-in-out">
          <div className='card-text' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out" >
            <h3>digital marketing</h3>
          </div>
          <div className='card-image' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out"><img src={network}  alt="network logo"/></div>
        </div>
        <div className='card-wrapper' data-aos="fade-up" data-aos-easing="ease-in-out">
          <div className='card-text' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out">
            <h3>e-commerce</h3>
          </div>
          <div className='card-image' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out"><img src={onlineshop}  alt="online store logo"/></div>
        </div>
        <div className='card-wrapper' data-aos="fade-up" data-aos-easing="ease-in-out">
          <div className='card-text' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out">
            <h3>ui + ux design</h3>
          </div>
          <div className='card-image' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out"><img src={frontend}  alt="front end logo"/></div>
        </div>
        <div className='card-wrapper' data-aos="fade-up" data-aos-easing="ease-in-out">
          <div className='card-text' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out">
            <h3>search engine optimisation</h3>
          </div>
          <div className='card-image' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out"><img src={seo}  alt="seo logo"/></div>
        </div>
        <div className='card-wrapper' data-aos="fade-up" data-aos-easing="ease-in-out">
          <div className='card-text' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out">
            <h3>brand development</h3>
          </div>
          <div className='card-image' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out"><img src={meetingroom}  alt="meeting room logo"/></div>
        </div>
      </div>
    </div>
  );
}

export default CardsComponent;
