import '../Styles/BackgroundComponentStyle.css';
import shapei from '../Media/blobs/shapei.svg'
import shapeii from '../Media/blobs/shapeii.svg'
import ellipse from '../Media/blobs/ellipse.svg'

import {useEffect} from "react";


function BackgroundComponent({poss1, poss2, pose1, pose2, e2display, id}) {

  useEffect(() => {

    let heroElement = document.getElementById('hero-component')
    let splitElement = document.getElementById('split-id-one')
    let cardsElement = document.getElementById('cards-component')
    let footerElement = document.getElementById('footer-component')
    let contactElement = document.getElementById('contact-page')

    

    if(heroElement != null){
      let heroObserver = new IntersectionObserver(callbackFuncHero, options);    
      heroObserver.observe(heroElement);
    }
    
    if(splitElement != null){
      let splitObserver = new IntersectionObserver(callbackFuncSplit, optionsSplit);    
      splitObserver.observe(splitElement);
    }

    if(cardsElement != null){
      let cardsObserver = new IntersectionObserver(callbackFuncCards, options);    
      cardsObserver.observe(cardsElement);
    }

    if(footerElement != null){
      let footerObserver = new IntersectionObserver(callbackFuncFooter, options);    
      footerObserver.observe(footerElement);
    }
    if(contactElement != null){
      let contactObserver = new IntersectionObserver(callbackFuncContact, options);    
      contactObserver.observe(contactElement);
    }

  });

  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0
  };

  let optionsSplit = {
    root: null,
    rootMargin: '0px',
    threshold: 0
  };

  function callbackFuncHero(entries, observer)
  {
    let heroElement = document.getElementById('hero-component')
    if(heroElement != null){

    let parent = document.getElementById('background-blob-storage-hero')
    entries.forEach(entry => {
      if(entry.isIntersecting){
        parent.classList.add('blob-color-1')
      } else{
        parent.classList.remove('blob-color-1')
      }
    });
  }
  }
  
  function callbackFuncSplit(entries, observer)
  {
    let splitElement = document.getElementById('split-id-one')
    if(splitElement != null){
    entries.forEach(entry => {
      let parent = document.getElementById('background-blob-storage-split')
      if(entry.isIntersecting){
        parent.classList.add('blob-color-2')
      } else{
        parent.classList.remove('blob-color-2')
      }
    });
  }
  }
  
  function callbackFuncCards(entries, observer)
  {
    let cardsElement = document.getElementById('cards-component')
    if(cardsElement != null){
    entries.forEach(entry => {
      let parent = document.getElementById('background-blob-storage-cards')
      if(entry.isIntersecting){
        parent.classList.add('blob-color-3')
      } else{
        parent.classList.remove('blob-color-3')
      }
    });
  }
  }
  function callbackFuncFooter(entries, observer)
  {
    let footerElement = document.getElementById('footer-component')
    if(footerElement != null){
    entries.forEach(entry => {
      let parent = document.getElementById('background-blob-storage-footer')
      if(entry.isIntersecting){
        parent.classList.add('blob-color-4')
      } else{
        parent.classList.remove('blob-color-4')
      }
    });
  }
  }

  function callbackFuncContact(entries, observer)
  {
    let contactPageElement = document.getElementById('contact-page')
    if(contactPageElement != null){

    let parent = document.getElementById('background-blob-storage-contact')
    entries.forEach(entry => {
      if(entry.isIntersecting){
        parent.classList.add('blob-color-1')
      } else{
        parent.classList.remove('blob-color-1')
      }
    });
  }
  }
  

  // console.log(poss1, poss2, pose1, pose2, id)


  return (
    <div className="background-component" data-aos="fade-zoom-in"
    data-aos-easing="ease-out-cubic"
    data-aos-offset="0"
    data-aos-duration="1500"
    >
      
      <div className="background-blob-storage-hero" id={id}>
        <div className='background-blob-container'>

          <div className="background-blob-vectori blob-i">
            <img 
              src={shapeii} alt="background art" 
              className="blob-img background-blob-vectori-img" 
              style={{left: poss1.x + '%', bottom: poss1.y + '%'}}>
            </img>
          </div>

          <div className="background-blob-vectorii blob-ii">
            <img 
              src={shapei} alt="background art" 
              className="blob-img background-blob-vectorii-img"
              style={{left: poss2.x + "%", bottom: poss2.y + "%"}}
            ></img>
          </div>

          <div className="background-blob-ellipse blob-iii">
            <img 
              src={ellipse} alt="background art" 
              className="blob-img background-blob-ellipse-img"
              style={{left: pose1.x + "%", bottom: pose1.y + "%"}}
            ></img>
          </div>

          <div className="background-blob-ellipsei blob-iii" style={{display: e2display}}>
            <img 
              src={ellipse} alt="background art" 
              className="blob-img background-blob-ellipsei-img"
              style={{left: pose2.x + "%", bottom: pose2.y + "%"}}
            ></img>
          </div>


        </div>      
      </div>
    </div>
  );
}

export default BackgroundComponent;
