import '../Styles/ContactPageStyle.css';
import FuzzLayer from '../Components/FuzzLayer'
import BackgroundComponent from '../Components/BackgroundComponent'
import ContactComponent from '../Components/ContactComponent'


function ContactPage() {
  window.scrollTo(0, 0);
  return (
    <div class="contact-page" id="contact-page">
            <BackgroundComponent 
        poss1={ {x:20,y:10} }
        poss2={ {x:-40,y:0} }
        pose1={ {x:-20,y:-60} }
        pose2={ {x:-40,y:-60} }
        e2display= { "auto" }
        id={"background-blob-storage-contact"}
      />
      <FuzzLayer />
      <ContactComponent />
    </div>
  );
}

export default ContactPage;
