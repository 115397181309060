import '../Styles/FooterComponentStyle.css';
import BackgroundComponent from '../Components/BackgroundComponent'

function FooterComponent() {
  return (
    <div className="footer-component" id="footer-component" >
            <BackgroundComponent 
        poss1={ {x:20,y:0} }
        poss2={ {x:-10,y:0} }
        pose1={ {x:10,y:-50} }
        pose2={ {x:-5,y:-40} }
        e2display= { "none" }
        id={"background-blob-storage-footer"}
      />
      <div className="body-container" data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-anchor-placement="bottom-bottom"> 
        <h1>10</h1>
        <h1>32</h1>
        <h3>digital</h3>
      </div>

      <div className='footer-links-container' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-anchor-placement="bottom-bottom">
        {/* <a href='www.google.com' >link 1</a>
        <a href='www.google.com' >link 2</a>
        <a href='www.google.com' >link 3</a> */}
      </div>

      <div className='footer-right-container' data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out" data-aos-anchor-placement="bottom-bottom">

        <div className='footer-text-container'>
          <h5>lets speak about new ideas, or come to us with existing projects. we bet they're great. no, we don't say that to everyone.</h5>
        </div>

        <div className='footer-button-container'>
          <a href="/contact">get in touch</a>
        </div>
      </div>
      
    </div>
  );
}

export default FooterComponent;
