import '../Styles/HeroComponentStyle.css';
import BackgroundComponent from '../Components/BackgroundComponent'

function HeroComponent() {
  return (
    <div className="hero-component" id="hero-component" 

    >
      <BackgroundComponent 
        poss1={ {x:20,y:0} }
        poss2={ {x:-10,y:0} }
        pose1={ {x:10,y:-50} }
        pose2={ {x:-5,y:-40} }
        e2display= { "none" }
        id={"background-blob-storage-hero"}
      />
      <div className="body-container"     
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-offset="0"
        data-aos-duration="2000">
        <h1>10</h1>
        <h1>32</h1>
        <h3>digital</h3>
      </div>
      
    </div>
  );
}

export default HeroComponent;
