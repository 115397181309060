import './Styles/App.css';
import './Pages/HomePage.js';
import HomePage from './Pages/HomePage.js';
import ContactPage from './Pages/ContactPage.js';
import HeaderComponent from './Components/HeaderComponent';
import FooterComponent from './Components/FooterComponent';
import {useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Route, Routes } from 'react-router-dom';


function App() {
  /* eslint-disable */
  document.querySelectorAll('a[href^="#"]').forEach(anchor => {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
    });
});

console.log(`
\r\n______\/\\\\\\_____\/\\\\\\\\\\\\\\________\/\\\\\\\\\\\\\\\\\\\\_____\/\\\\\\\\\\\\\\\\\\_____        \r\n __\/\\\\\\\\\\\\\\___\/\\\\\\\/\/\/\/\/\\\\\\____\/\\\\\\\/\/\/\/\/\/\/\\\\\\__\/\\\\\\\/\/\/\/\/\/\/\\\\\\___       \r\n  _\\\/\/\/\/\/\\\\\\__\/\\\\\\____\\\/\/\\\\\\__\\\/\/\/______\/\\\\\\__\\\/\/\/______\\\/\/\\\\\\__      \r\n   _____\\\/\\\\\\_\\\/\\\\\\_____\\\/\\\\\\_________\/\\\\\\\/\/_____________\/\\\\\\\/___     \r\n    _____\\\/\\\\\\_\\\/\\\\\\_____\\\/\\\\\\________\\\/\/\/\/\\\\\\_________\/\\\\\\\/\/_____    \r\n     _____\\\/\\\\\\_\\\/\\\\\\_____\\\/\\\\\\___________\\\/\/\\\\\\_____\/\\\\\\\/\/________   \r\n      _____\\\/\\\\\\_\\\/\/\\\\\\____\/\\\\\\___\/\\\\\\______\/\\\\\\____\/\\\\\\\/___________  \r\n       _____\\\/\\\\\\__\\\/\/\/\\\\\\\\\\\\\\\/___\\\/\/\/\\\\\\\\\\\\\\\\\\\/____\/\\\\\\\\\\\\\\\\\\\\\\\\\\\\\\_ \r\n        _____\\\/\/\/_____\\\/\/\/\/\/\/\/_______\\\/\/\/\/\/\/\/\/\/_____\\\/\/\/\/\/\/\/\/\/\/\/\/\/\/\/__\r\n                                            
`);

useEffect(() => {
  AOS.init({disable: 'mobile'});
}, [])
  return (
    <div className="App">
      <HeaderComponent />
      <Routes>
      <Route path="/" element={ <HomePage/> } />
      <Route path="/contact" element={ <ContactPage/> } />
      </Routes>
      <FooterComponent />
      
      
    </div>
  );
}

export default App;
