import '../Styles/ContactComponentStyle.css';

function ContactComponent() {
  return (
    <div className="contact-component" id="contact-component" >
        {/* <div className='contact-title' >
          <h1>contact</h1>
        </div>
        <div className='contact-body'>
          <div className='contact-left-container'>
                <div className='contact-left' >
                    <h4>we would love to hear about your project.</h4>
                    <p>our bespoke services will help you reach your target audience with clean and effective delivery.  </p> 
                </div>
          </div>
          <div className='contact-right-container'>
                <div className='contact-right' >
                    <h4>drop us a line</h4>
                    <p>hello@1032digital.com</p>

                </div>
          </div>
        </div> */}
        <div className='contact-words'>
          <h4>drop us a line</h4>
          <p>hello@1032digital.com</p>
        </div>
    </div>
  );
}

export default ContactComponent;
