import '../Styles/SpacerComponentStyle.css';

function SpacerComponent() {
  return (
    <div className="spacer-component" >

      
    </div>
  );
}

export default SpacerComponent;
