import {useEffect} from "react";
import '../Styles/BigTextComponentStyle.css';

function BigTextComponent( {text1, textFx, text2, setId}) {
  useEffect(() => {

    let observer = new IntersectionObserver(callbackFunc, options);    
    observer.observe(document.getElementById(setId));
    
  });

  function callbackFunc(entries, observer)
  {
    var bigParent = document.getElementById(setId)
    if(bigParent)
    var parent = bigParent.getElementsByClassName('big-text-a')[0]
    if(parent)
    entries.forEach(entry => {
      if(entry.isIntersecting){
        parent.classList.add('big-text-a-fx')
      } else{
        parent.classList.remove('big-text-a-fx')
      }
    });
  }
  
  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.3
  };

  return (
    <div className="big-text-component " data-aos="fade-up" id={setId}>

        <h1 className="big-text-h1 margin-spacing" >{text1}<a className="big-text-a" href="https://www.1032digital.com">{textFx}</a>{text2}</h1>
      
    </div>
  );
}

export default BigTextComponent;
