import {useEffect} from "react";
import '../Styles/SplitComponentStyle.css';
import Rotate3dComponent from '../Components/Rotate3dComponent'
import BackgroundComponent from '../Components/BackgroundComponent'

function SplitComponent({setId}) {
  useEffect(() => {

    let observer = new IntersectionObserver(callbackFunc, options);    
    observer.observe(document.getElementById(setId));
    
  });

  function callbackFunc(entries, observer)
  {
    var doc = document.getElementById(setId)
    if(doc)
    var parent = doc.getElementsByClassName('split-a')[0]
    if(parent)
    entries.forEach(entry => {
      if(entry.isIntersecting){
        parent.classList.add('split-a-fx')
      } else{
        parent.classList.remove('split-a-fx')
      }
    });
  }
  
  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.3
  };

  return (
    <div className="split-component-container margin-spacing">
      <div className="split-component" data-aos="fade-up" id={setId}>
        <div className="split-container split-l">
          <h3 className="split-h1" data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out">
            let us&nbsp;
            <a className="split-a" href="https://www.1032digital.com">elevate </a>
            your brand.
          </h3>
          <p className="split-para" data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out">
          We craft websites that <strong>engage</strong> and <strong>convert</strong>.
          <br/><br/>
          We drive <strong>growth</strong> through effective digital marketing strategies. 
          <br/><br/>
          Whether you're an individual or a small business, we can help you realise your potential.</p>
          {/* <p className="split-accent" data-aos="fade-in" data-aos-delay="200" data-aos-duration="2000" data-aos-easing="ease-in-out"> a wee extra bit of steeze</p> */}
        </div>
        <div className="split-container split-r">
          <Rotate3dComponent />
        </div>
      </div>
      <BackgroundComponent 
        poss1={ {x:-40,y:15} }
        poss2={ {x:30,y:-15} }
        pose1={ {x:15,y:-50} }
        pose2={ {x:0,y:0} }
        e2display= { "none" }
        id={"background-blob-storage-split"}
      />
    </div>
  );

}

export default SplitComponent;
